import * as React from 'react';
import {
  CardStyleInterpolators,
  StackNavigationOptions,
  createStackNavigator
} from '@react-navigation/stack';

import { Dimensions, Platform } from 'react-native';
import { enableScreens } from 'react-native-screens';
import { Routes } from './routes';
import { RootStackParams } from './interfaces';
import Login from '../screens/Login';
import LoginWeb from '../screens/LoginWeb';
import HeaderApp from '@/components/header/HeaderApp';
import { RootState, useAppSelector } from '@/redux/store';
import IntroSlider from '@/components/intro/IntroSlider';
import PrivacyAndTerms from '@/screens/PrivacyAndTerms';

enableScreens();

const Stack = createStackNavigator<RootStackParams>();

const screenOptions: StackNavigationOptions = {
  gestureEnabled: true,
  gestureResponseDistance: Dimensions.get('screen').width,
  animationEnabled: false,
  header: () => <HeaderApp />,

  cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
  headerTitleStyle: {
    fontFamily: 'Bold'
  },
  headerTitleAlign: 'center'
};

function PublicStack() {
  const introShown = useAppSelector((state: RootState) => state.user.introShown);

  return (
    <Stack.Navigator
      initialRouteName={introShown ? Routes.Login : Routes.Intro}
      screenOptions={screenOptions}
    >
      <Stack.Screen
        name={Routes.Intro}
        component={IntroSlider}
        options={{
          headerShown: false
        }}
      />
      <Stack.Screen
        name={Routes.Login}
        component={Platform.OS === 'web' ? LoginWeb : Login}
        options={{
          headerShown: false
        }}
      />
      <Stack.Screen
        name={Routes.PrivacyAndTerms}
        component={PrivacyAndTerms}
        options={{
          headerShown: false
        }}
      />
    </Stack.Navigator>
  );
}

export default React.memo(PublicStack);
