import { deleteWorkflowOfState } from '@/redux/actionTypes';
import axiosInstance from '@/utils/network/instanceAxios';
import { ConnectionData } from './interface';

export const getWorkflows =
  ({ teamId }) =>
  async (dispatch) => {
    try {
      const response = await axiosInstance.get(`workflows/${teamId}`);

      return response.data;
    } catch (error) {
      console.log('error to get workflows', error);

      throw error?.response?.data;
    }
  };

export const createWorkflow = (workflow) => async (dispatch) => {
  try {
    const response = await axiosInstance.post(`workflows`, { ...workflow });
    return response.data;
  } catch (error) {
    console.log('error create workflow', error);
    throw error?.response;
  }
};

export const changePriority =
  ({
    id,
    currentPriority,
    newPriority,
    team_id
  }: {
    id: string;
    currentPriority: number;
    newPriority: number;
    team_id: string;
  }) =>
  async (dispatch) => {
    try {
      const response = await axiosInstance.patch(
        `/workFlows/updatePriority/${team_id}/${id}/${currentPriority}/${newPriority}`
      );
      return response.data;
    } catch (error) {
      console.log('error change priority', error);
      throw error?.response;
    }
  };

export const changeStatusWorkflow =
  ({ id, status }) =>
  async (dispatch) => {
    try {
      const response = await axiosInstance.patch(`/workFlows/activateWorkflow/${id}/${status}`);
      return response.data;
    } catch (error) {
      console.log('error change status', error);
      throw error?.response;
    }
  };

export const editWorkflow =
  ({ id, workflow }) =>
  async (dispatch) => {
    try {
      const response = await axiosInstance.patch(`/workflows/${id}`, { ...workflow });
      return response.data;
    } catch (error) {
      console.log('error edit workflow', error);
      throw error?.response;
    }
  };

export const deleteWorkflow =
  ({ id, idString }) =>
  async (dispatch) => {
    try {
      const response = await axiosInstance.delete(`/workflows/${id}`);
      dispatch(deleteWorkflowOfState({ id, idString }));
      return response.data;
    } catch (error) {
      console.log('error delete workflow', error);
      throw error?.response;
    }
  };

export const connectChannelWpp = (props: ConnectionData) => async (dispatch) => {
  try {
    const response = await axiosInstance.post(`/channels/connect/`, props);

    return response.data;
  } catch (error) {
    console.log('error delete workflow', error);
    throw error?.response;
  }
};
