import React, { useMemo } from 'react';
import { RouteSettings } from '@/routers/routes';

import i18n from '@/providers/Localization';
import { Platform } from 'react-native';
import SettingsNotifications from '@/screens/SettingsNotifications';
import SettingsChat from '@/screens/SettingsChat';
import SettingsCustomizations from '@/screens/SettingsCustomizations';
import Help from '@/screens/Help';
import HeaderSettingsStack from '@/atoms/HeaderSettingsStack';
import CustomDrawerComponent, { RouteDrawer } from './CustomDrawer/CustomDrawerComponent';
import ContentSiderbarLeftSettings from '../components/sidebar/sidebarSettings/ContentSiderbarLeftSettings';
import colors from '../../config/colors';
import { RootState, useAppSelector } from '@/redux/store';
import { HEIGHT_HEADER_LAYOUT, MOBILE_SCREEN } from '@/utils/globales';
import NewAutomatization from '@/components/partials/automatizations/NewAutomatization';
import NotFound404 from '@/screens/NotFound404';
import SettingsChannels from '@/screens/SettingsChannels';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { StackScreenProps } from '@react-navigation/stack';
import { SettingsStackParams } from './interfaces';
import Connections from '@/screens/Connections';
import Fallback from '@/screens/Fallback';

/**
 *  Settings is a wrapper around the React Navigation Drawer to provide
 * a more customizable and flexible drawer component for the application. It allows
 * various configurations like fixed sidebar, custom styling, etc.
 * @returns  {React.ReactElement}
 */

function Settings(routeSetting: StackScreenProps<SettingsStackParams>): React.ReactElement {
  const themeMode = useAppSelector((state: RootState) => state.settings.themeMode);
  const isPxsol = useAppSelector((state: RootState) => state.user?.user?.isPxsol);

  const team = useMemo(
    () => routeSetting?.route?.params?.team,
    [routeSetting?.route?.params?.team]
  );

  const [isMobile] = useMediaQuery([
    {
      maxWidth: MOBILE_SCREEN
    }
  ]);

  const urlsRoutes: RouteDrawer[] = useMemo(
    () => [
      {
        id: '3',
        name: RouteSettings.settingsChat,
        component: team ? SettingsChat : () => <NotFound404 text={'Equipo incorrecto'} />,
        options: {
          headerShown: true,
          headerTitle: i18n.t('navigation.automatizations'),
          title: i18n.t('navigation.automatizations'),
          headerStyle: {
            backgroundColor: colors[themeMode]?.backgroundSecondary,
            height: HEIGHT_HEADER_LAYOUT,
            borderBottomWidth: 1,
            borderBottomColor: colors[themeMode]?.border,
            paddingRight: 10
          },
          headerRight: () => <NewAutomatization {...routeSetting} />,
          headerTintColor: colors[themeMode]?.text,
          headerRightContainerStyle: {
            paddingRight: 10
          }
        },
        usersPlans: 'internal',
        initialParams: {
          team
        }
      },
      {
        id: '2',
        name: RouteSettings.settingsChannels,
        component: SettingsChannels,
        options: {
          headerShown: true,
          headerTitle: i18n.t('navigation.channels'),
          title: i18n.t('navigation.channels'),
          headerStyle: {
            backgroundColor: colors[themeMode]?.backgroundSecondary,
            height: HEIGHT_HEADER_LAYOUT,
            borderBottomWidth: 1,
            borderBottomColor: colors[themeMode]?.border
          },
          headerTintColor: colors[themeMode]?.text
        },
        headerComponent: () => <HeaderSettingsStack idString="navigation.channels" />,
        usersPlans: 'internal',
        initialParams: {
          team
        }
      },

      {
        id: '1',
        name: RouteSettings.settingsNotifications,
        component: SettingsNotifications,
        options: {
          headerShown: true,
          headerTitle: i18n.t('navigation.notifications'),
          title: i18n.t('navigation.notifications'),
          headerStyle: {
            backgroundColor: colors[themeMode]?.backgroundSecondary,
            height: HEIGHT_HEADER_LAYOUT,
            borderBottomWidth: 1,
            borderBottomColor: colors[themeMode]?.border
          },
          headerTintColor: colors[themeMode]?.text
        },
        headerComponent: () => <HeaderSettingsStack idString="navigation.notifications" />,
        usersPlans: 'internal',
        initialParams: {
          team
        }
      },
      {
        id: '4',
        name: RouteSettings.settingsCustomizations,
        component: SettingsCustomizations,
        options: {
          headerShown: true,
          headerTitle: i18n.t('navigation.customization'),
          title: i18n.t('navigation.customization'),
          headerStyle: {
            backgroundColor: colors[themeMode]?.backgroundSecondary,
            height: HEIGHT_HEADER_LAYOUT,
            borderBottomWidth: 1,
            borderBottomColor: colors[themeMode]?.border
          },
          headerTintColor: colors[themeMode]?.text
        },
        headerComponent: () => <HeaderSettingsStack idString="navigation.customization" />,
        usersPlans: 'internal',
        initialParams: {
          team
        }
      },
      {
        id: '5',
        name: RouteSettings.help,
        component: Help,
        options: {
          headerShown: true,
          headerTitle: i18n.t('navigation.help'),
          title: i18n.t('navigation.help'),
          headerStyle: {
            backgroundColor: colors[themeMode]?.backgroundSecondary,
            height: HEIGHT_HEADER_LAYOUT,
            borderBottomWidth: 1,
            borderBottomColor: colors[themeMode]?.border
          },
          headerTintColor: colors[themeMode]?.text
        },
        headerComponent: () => <HeaderSettingsStack idString="navigation.help" />,
        usersPlans: 'internal',
        initialParams: {
          team
        }
      },
      {
        id: '6',
        name: RouteSettings.connections,
        component: isPxsol ? Connections : Fallback,
        options: {
          headerShown: true,
          headerTitle: i18n.t('navigation.connections'),
          title: i18n.t('navigation.connections'),
          headerStyle: {
            backgroundColor: colors[themeMode]?.backgroundSecondary,
            height: HEIGHT_HEADER_LAYOUT,
            borderBottomWidth: 1,
            borderBottomColor: colors[themeMode]?.border
          },
          headerTintColor: colors[themeMode]?.text
        },
        headerComponent: () => <HeaderSettingsStack idString="navigation.connections" />,
        usersPlans: 'internal',
        initialParams: {
          team
        }
      }
    ],
    [team, colors, themeMode, isMobile, isPxsol]
  );

  return team ? (
    <CustomDrawerComponent
      DrawerSidebar={ContentSiderbarLeftSettings}
      routes={urlsRoutes}
      drawePermanent={!!(Platform.OS === 'web' && !isMobile)}
      maxWidthSidebar={175}
      borderTopLeft={10}
    />
  ) : (
    <NotFound404 text={'Equipo incorrecto'} />
  );
}

/**
 * @property {React.ReactElement} Settings The Settings component.
 * @export Exports the Settings component.
 * @example <Settings />
 * @see https://reactnavigation.org/docs/drawer-navigator/
 *
 */

export default Settings;
