import { ILocalization } from './es';

const resource: ILocalization = {
  Audio: 'Audio',
  additionalComment: 'Additional comment',
  accept: 'Accept',
  actived: 'Actived',
  answer: 'Answer',
  assignees: 'Assignees',
  activated: 'Activated',
  agents: 'Agents',
  applyFilters: 'Apply filters',
  associations: 'Associations',
  attach: 'Attach',
  File: 'File',
  Gif: 'Gif',
  Image: 'Image',
  Paste: 'Paste',
  Sticker: 'Sticker',
  aFile: 'a file',
  aSticker: 'a sticker',
  abrir: 'Open',
  ajustsOfHotel: 'Hotel settings',
  ajustOfApp: 'App settings',
  bot: 'Bot',
  bussines_id: 'Bussines Acount ID',
  centerOfHelp: 'Help center',
  changingTeam: 'Changing team',
  chargingData: 'Charging data...',
  channels: 'Channels',
  channelData: 'Phone Number',
  channelID: 'Channel ID',
  channelHotelName: 'Channel name',
  channelLabel: 'Channel label',
  teamId: 'Team ID',
  companyId: 'Company ID',
  channelNamespace_id: 'Template Namespace',
  idChannelDefinition: 'ID channel definition (1: WhatsApp, 2: Facebook, 3: Instagram)',
  instructions: 'Instructions',
  currency: 'Currency',
  colorAplication: 'Application color',
  closeFragment: 'Close fragment',
  copyToClipboard: 'Copy to clipboard',
  copyTheError: 'Copy the error',
  chatbot: 'Chatbot',
  actions: {
    actions: 'Actions',
    activate: 'Activate',
    addRangeDayAndTime: 'Add range day and time',
    addChannel: 'Add channel',
    AssociationBoooking: 'Associate booking',
    associateContact: 'Associate contact',
    add: 'Add',
    associateRoom: 'Associate room',
    associate: 'Associate',
    apply: 'Apply',
    cancel: 'Cancel',
    continue: 'Continue',
    connectFree: 'Connect for free',
    update: 'Update',
    close: 'Close',
    confirm: 'Confirm',
    copy: 'Copy',
    createQuery: 'Create query',
    create: 'Create',
    configure: 'Configure',
    delete: 'Delete',
    connect: 'Connect',
    deleteMessage: 'Delete message',
    edit: 'Edit',
    new: 'New',
    newLabel: 'New label',
    forward: 'Forward',
    generateResponse: 'Generate response',
    initChat: 'Init chat',
    login: 'Login',
    link: 'Link',
    next: 'Next',
    previous: 'Previous',
    reload: 'Reload',
    reply: 'Reply',
    rewrite: 'Rewrite',
    changeContext: 'Change context',
    changePrompt: 'Change prompt',
    talkToSales: 'Talk to sales',
    save: 'Save',
    select: 'Select',
    selectBot: 'Select bot',
    view: 'View',
    transcribe: 'Transcribe',
    transcribing: 'Transcribing...',
    typeAName: 'Type a name',
    typeAChannelData: 'Type a channel data',
    typeAChannelId: 'Type a channel ID',
    typeABussines_id: 'Type a business ID',
    typeAChannelHotelName: 'Type a channel hotel name',
    typeANamespace_id: 'Type a Template Namespace',
    unassign: 'Unassign',
    newAutomatization: 'New automatization',
    sendOfTemplates: 'Send of templates',
    automaticResponse: 'Automatic response',
    upgradePlan: 'Upgrade plan',
    validate: 'Validate',
    validating: 'Validating'
  },
  automatizations: {
    automatizationCreated: 'Automatization created successfully',
    automatizationUpdated: 'Automatization updated successfully',
    dragAndDropTitle:
      'Important! The order of the workflows here determines the execution sequence.',
    dragAndDrop: 'Drag and place the cards in the desired order to define priority.',
    selectTheTriggerOfAutomation: 'Select the trigger of automation',
    selectTheChannel: 'Select the channel',
    youSureCancelEdit: 'Are you sure you want to cancel the edit?',
    channelTooltip: 'Select the channel where the automation will be executed',
    inconmingMessage: 'Incoming message',
    inconmingMessageTooltip: 'Select the incoming message',
    typeConditions: 'Must be met:',
    typeConditionsTooltip: 'Select the type of conditions',
    init: 'Init',
    initTooltip: 'Select the init',
    end: 'End',
    noEnd: 'No end',
    endTooltip: 'Select the end',
    initTime: 'Init time',
    triggerTooltip: 'Select the trigger',
    initTimeTooltip: 'Select the init time',
    endTime: 'End time',
    endTimeTooltip: 'Select the end time',
    priorityChanged: 'Priority changed successfully',
    errorToChangePriority: 'Error changing priority',
    templatesTooltip: 'Select the templates',
    botTooltip: 'Select the bot',
    dayOfWeek: 'Day of the week',
    dayOfWeekTooltip: 'Select the day of the week',
    errorToDeleteAutomatization: 'Error deleting automatization',
    deleteAutomatization: 'Delete automatization',
    deleteAutomatizationDescription: 'Are you sure you want to delete this automatization?',
    deletedAutomatization: 'Deleted automatization'
  },
  axiosError: {
    fileSizeExceededLimitForThisTypeOfFile: 'File size exceeded limit for this type of file',
    notFileFound: 'File not found',
    conversationIdNotFound: 'Conversation ID not found',
    typeFileNotFound: 'Type file not found',
    errorDistributingFile: 'Error distributing file'
  },
  navigation: {
    automatizations: 'Automatizations',
    home: 'Home',
    profile: 'Profile',
    clients: 'Clients',
    chat: 'Chat',
    channels: 'Channels',
    createTicket: 'Create ticket',
    connections: 'Connections',
    settings: 'Settings',
    post: 'Post',
    notifications: 'Notifications',
    newConversation: 'New conversation',
    phoneTooltip:
      "Enter the contact's phone number without 0 or 15, for Argentine numbers, e.g.: 9 11 1234 5678. The international format will be completed automatically.",
    phoneHelperText:
      'The country code is automatically filled in. For numbers in Argentina, the area code is 9.',
    customization: 'Customization',
    help: 'Help',
    todos: 'All',
    sin_asignar: 'Unassigned',
    asignados_a_mi: 'Assigned to me',
    cerrados: 'Closed',
    grupos: 'Groups',
    expired: 'Expired',
    settingsChangelog: 'Changelog',
    addEmbedding: 'Add to knowledge base',
    newAutomatizationTemplate: 'New template automatization',
    newAutomatizationBot: 'New bot automatization',
    newAutomatizationRange: 'New range of days and times',
    dinamicContent: 'Dynamic content',
    welcome: 'Welcome'
  },
  templates: {
    empty: 'Oops! To start this chat you need a pre-approved template.',
    title: 'Templates',
    subtitle: 'Create and manage your templates',
    createTemplateOfFirstContact:
      'Due to Meta policies, it is not possible to send messages that are not approved. Create templates to be approved by Meta and use them to open conversations. ',
    newTemplate: 'New template',
    create: 'Create template',
    save: 'Save',
    cancel: 'Cancel',
    send: 'Send',
    share: 'Share',
    talkToSales: 'Book a Free Demo',
    selectTemplate: 'Select template',
    sendTemplate: 'Send template'
  },
  addMembers: 'Add members',
  addParticipants: 'Add participants',
  ageOfMinor: 'Age of minor',
  minorAge: 'Minor age',
  anAudio: 'an audio',
  alert: 'Alert',
  anImage: 'an image',
  assignResponsible: 'Assign responsible',
  assignee: 'Assignee',
  assigneeError: 'Error assigning assignee',
  assigneeSuccess: 'Assignee successfully assigned',
  avaliableChannels: 'Avaliable channels',
  availableTemplatesForFirstContact: 'Available templates for first contact',
  back: 'Back',
  birthdate: 'Birthdate',
  bots: 'Bots',
  brand: 'Pxsol Conversations',
  booking: 'Booking',
  bookings: 'Bookings',
  businessname: 'Business name',
  closed: 'Closed',
  checkIn: 'Check-in',
  checkOut: 'Check-out',
  config: 'Setup',
  contact: 'Contact',
  contactos: 'Contacts',
  conversation: 'Conversation',
  continue: 'Continue',
  conversationPending:
    'Conversation started; awaiting customer response. To close the conversation and send a new message, 24 hours must elapse',
  company: 'Company',
  commingSon: 'Coming soon',
  conversations: 'Conversations',
  connect: 'Connect',
  connectAnAssistantToYourTeam: 'Connect an assistant to your team',
  copied: 'Copied',
  yourTeamNotHaveAnAssistant: 'Your team does not have an assistant',
  createContact: 'Create contact',
  createBooking: 'Create booking',
  cuit: 'Cuit',
  current: 'Current',
  currencyChanged: 'Currency changed successfully',
  channel: 'Channel',
  darkTheme: 'Dark Theme',
  Dayisnotallowed: 'Day is not allowed',
  dataOfContact: 'Data of contact',
  description: 'Description',
  download: 'Download',
  downloadoConversations: 'Downloading conversations',
  dinamicContent: {
    dinamicContent: 'Dynamic content',
    template: 'Templates',
    actions: 'Actions'
  },
  email: 'Email',
  empresa: 'Company',
  error: 'Error',
  establecimiento: 'Establishment',
  establishment: 'Establishment',
  embedding: 'Embedding',
  exit: 'Exit',
  exitGroup: 'Exit group',
  editContact: 'Edit contact',
  expired: 'Expired',
  filters: 'Filters',
  frecuentContacts: 'Frecuent contacts',
  filtros: {
    asignados_a_mi: 'Assigned to me',
    cerrados: 'Closed',
    expired: 'Expired',
    grupos: 'Groups',
    sin_asignar: 'Unassigned',
    todos: 'All',
    abiertos: 'Received'
  },
  finish: 'Finish',
  failed: 'Failed',
  generalActivityIndicatorText: 'Loading...',
  generalErrorText: 'An error occurred, please try again later.',
  guests: 'Guests',
  gotToPms: 'Go to PMS',
  greaters: 'Greaters',
  getMore: 'Get more',
  goToFirstMessage: 'Go to first message',
  goToLastMessage: 'Go to last message',
  hi: 'Hi',
  here: 'here',
  infoContact: 'Info contact',
  infoConver: 'Info conversation',
  infoOfContact: 'Info of contact',
  infoOfcompany: 'Info of company',
  info: 'Info',
  internal: 'Internal',
  important: 'Important',
  incident: 'Incident',
  improvement: 'Improvement',
  lastname: 'Lastname',
  logout: 'Logout',
  location: 'Location',
  loadingConversations: 'Loading conversations',
  language: 'Language',
  labels: 'Labels',
  languageChanged: 'Language changed successfully',
  loadingTemplates: 'Loading templates',
  loadingData: 'Loading data...',
  loadMore: 'Load more',
  members: 'Members',
  message: 'Messages',
  messageDeleted: 'Message deleted',
  messageUnsuported: 'Message type not supported',
  messageError: {
    alreadyExistAssociationBooking: 'This conversation already has an associated booking',
    alreadyExistAssociationContact: 'This conversation already has an associated contact',
    noBookingAssociated: 'No booking associated',
    moreOneBookingAssociated: 'More than one booking associated',
    checkOut: 'Check-out date must be greater than check-in date',
    checkIn: 'Check-in date must be less than check-out date',
    checkOutRequired: 'Check-out date is required',
    checkInRequired: 'Check-in date is required',
    converNotExist: 'Conversation does not exist',
    errorGetFirstData: 'Error getting first data',
    defineAgeOfMinor: 'The age of the minors needs to be defined.',
    errorCiut: 'The document number should just be 11 digits long and only numbers',
    errorMessages: 'Error getting messages',
    errorNameIsNotEmpty: 'Name is not empty',
    errorNotClosedFragment: 'Error, to close the fragment it must first be allocated',
    errorShareFile: 'Error sharing file',
    errorToChangeNameOfGroup: 'Error to change name of group',
    errorToClosedFragment: 'Could not close fragment',
    errorToClosedFragmentIfUserIsNotAssignee: 'You are not the assignee of this conversation',
    errorToCreateConversation: 'Error creating conversation',
    errorToGetConversation: 'Error getting conversation',
    errorToDeleteParticipant: 'Error deleting participant',
    errorToCreateGroup: 'Error creating group',
    errorToUpdateStatus: 'Error updating status',
    errorToOutGroup: 'Error to out group',
    errorToUpdateNotification: 'Error updating notification',
    errorToAssociateBooking: 'Error associating booking',
    errorToAsscociateContact: 'Error associating contact',
    errorToCreateContact: 'Error creating contact',
    errorInServerRetryLater: 'Error in server, please try again later',
    errorContactAlreadyExist: 'Contact already exist',
    errorChangingLanguage: 'Error changing language',
    errorChangingCurrency: 'Error changing currency',
    errorToMarkConversationAsNotRead: 'Error marking conversation as not read',
    errorConversations: 'Error getting conversations',
    errorToGetValueOfLink: 'Error getting value of link',
    fileNotFound: 'File not found',
    filterInvalid: 'Invalid filter, please select a valid filter',
    fileSizeExceeded: 'File size exceeded, maximum size is 25MB',
    invalidPhone: "Oops! It looks like it's not a WhatsApp number.",
    noContatcs: 'No contacts',
    noConvers: 'No conversations',
    noConversations: 'No conversations',
    noConnectSocket: 'Error connecting to server',
    noCreateConversation: 'Error creating conversation',
    noCreateGroup: 'Error creating group',
    noAddParticipant: 'Error adding participant',
    noStickerFound: 'No sticker found',
    noDeleteParticipant: 'Error deleting participant',
    noPermissionNotification: 'No permission for notifications, please enable them',
    fieldRequiered: 'Field required',
    numberPhoneInvalid: 'Invalid phone number',
    invalidEmail: 'Invalid email',
    imageSizeExceeded: 'Image size exceeded, maximum size is 5MB',
    dateHaveToBeLessThanToday: 'Date have to be less than today',
    roomsGreaterThan1: 'The number of rooms must be greater than 1',
    ageLeast1: 'The age of the minors must be greater than',
    ageLessThan12: 'The age of the minors must be less than',
    errorToMoveConversationOfTeam: 'Error to move conversation of team',
    selectAnEmbedding: 'Select an embedding',
    completeTheField: 'Complete the fields',
    noBots: 'No have bots',
    noContacts: 'No hay contactos',
    videoCouldNotBeLoaded: 'Video could not be loaded',
    tooManyRequests: 'Too many requests',
    noConfigOfChannel: 'No config of channel',
    noAutomatizations: 'Ups! No automatizations',
    0: 'Failure to send message to cloud API. CODE: 0',
    130472:
      'WhatsApp will not be able to deliver this message, if you try to resend the message the same error will occur. If you must deliver the marketing template message to the customer, we recommend that you contact the customer by some means other than WhatsApp and ask them to send you a message so that you can resend the message within the customer service window. customer. CODE: 130472',
    131026:
      'Message failed to send because the recipient is not registered with WhatsApp. Check the number and try again. CODE: 131026',
    131047:
      'Message failed to send because more than 24 hours have passed since the customer last replied to this number. CODE: 131047',
    ERR_BAD_REQUEST: 'Bad request',
    2534080: 'The file is not supported. CODE: 2534080'
  },
  messages: {
    connectWhatsapp: "Haven't you linked your WhatsApp account yet?",
    connectWhatsappButton: 'Connect now',
    connectFacebook: "Haven't you linked your Facebook account yet?",
    connectFacebookButton: 'Connect now',
    connectInstagram: "Haven't you linked your Instagram account yet?",
    connectInstagramButton: 'Connect now',
    connectWidget: "Haven't you linked your Widget account yet?",
    connectWidgetButton: 'Connect now',
    contactsRanked: 'Frequent contacts',
    useAsPrompt: 'Use as prompte',
    loadingDefaultValue: 'Loading default value...',
    channelDisabled: 'Channel disabled',
    channelDisabledDescription:
      'The selected channel is disabled, to continue with the conversation, select another active channel.',
    closeFragmentSubtitle:
      'Attention! You are about to close this segment of the conversation. If you proceed, to continue the dialogue, you will need to initiate a new segment using a first contact template or wait for the client to respond. Do you wish to proceed with closing the current conversation?',
    closeWidget: 'Conversation finished',
    closeWidgetSubtitle:
      'Conversations originating from a web widget cannot be reopened because it is not possible to ensure that the user is still online. If the user closes the web page or does not send messages for 15 minutes, the conversation expires.',
    dateOfInit: 'Date of init',
    descriptionTickets: 'Description of the ticket, please be as detailed as possible',
    fileAlreadyExists: 'File already exists',
    fragmentOpen: 'Fragment open',
    generateSmartResponse: 'Generate smart response',
    initConversation: 'Init conversation',
    instructionForAgentResponse:
      'Enter the instruction on how you want the agent to draft the response. E.g.: Respond to the user kindly that we do not have availability, or respond to the user formally by providing the availability for the date of their inquiry.',
    instructionForAgentRewrite:
      'Tell the agent how to rewrite the text. E.g.: translate to English, draft as an email, etc.',
    extendAnswer: 'Extend message',
    labelName: 'Label name',
    noLabels: 'No labels',
    labelDescription: 'Label description',
    labelCreated: 'Label created successfully',
    labelsUpdated: 'Labels updated successfully',
    labelUpdated: 'Label updated successfully',
    labelDeleted: 'Label deleted successfully',
    labelSlogan:
      'Optimize the organization of your conversations with a new custom label! Creating a specific label for you and your team is a fantastic way to keep your conversations organized and accessible.',
    limitFreeMessages: 'You have limit of free messages',
    newContact: 'New contact',
    newBooking: 'New booking',
    new_incoming_message: 'New incoming message',
    generateInteligentResponse: 'Intelligent response',
    rewriteAnswer: 'Expand message',
    needHelp: 'Need help?',
    noConversationForThisFilter: 'No conversation for this filter',
    noConversationForThisSearch: 'No conversation for this search',
    noBookings: 'No bookings',
    noContactsForThisSearch:
      'The contact you are looking for does not appear among the suggestions. You can start a new conversation by entering the phone number in the field.',
    orderBy: 'Order by',
    order: 'Order',
    youNotHavePermissionToAccess: 'Connect communication channels and generate more bookings!',
    youNotHavePermissionToAccessDescription:
      'To start managing your messages, you need to connect your communication channels. If you have any questions, our team is here to help you!',
    yourTeamDonthaveChannelActive: 'Your team does not have active channels',
    yourTeamDonthaveChatContracts: 'Your team does not have chat contracts',
    markedAsImportant: 'Marked as important',
    markedAsNotImportant: 'Marked as not important',
    noResultForThisSearch: 'No result for this search',
    noAgentCreated: 'To generate a smart response you must first create a BOT user',
    infoSelectParticipant: 'Remove a participant from the members tab',
    resultOfSearch: 'Result of search',
    searchBooking: 'Search booking',
    incomming:
      "We're working on it! This section will be available soon. Thanks for your patience.",
    searchContact: 'Search contact',
    searchByParticipantOrContact: 'Email - phone - name',
    slectConversation: 'Select a conversation to start chatting',
    selectEmbedding: 'Select embedding',
    labelTask: '| Does the hotel offer activities for children?',
    maxCharacters: 'Maximum characters',
    maxCharactersDescription: 'Maximum characters limit reached',
    limitReached:
      'Has reached the maximum character limit, you can separate the message into shorter messages.',
    labelResponse:
      '| Yes, the hotel offers several activities for children: we have special activities for children during the weekends.',
    slogan:
      'Send and receive messages from a single place to give scale and automation to the management of your hotel.',
    successToChangueNameOfGroup: 'Name of group changed successfully',
    successToClosedFragment: 'The fragment was closed successfully',
    successToDeleteParticipant: 'Participant deleted successfully',
    successToChangeStatus: 'Status changed successfully',
    successToOutGroup: 'You left the group',
    successToUpdateNotification: 'Notification updated successfully',
    successToAssociateBooking: 'Booking associated successfully',
    noIsPosibleUnassigneeUser: 'It is not possible to unassignee the user',
    welcome: 'Welcome to Pxsol Conversations',
    successToChangeNameOfGroup: 'Name of group changed successfully',
    selectContactToAssignAsLead: 'Select contact to assign as lead',
    toCreateGropuDefineAName: 'To create a group, define a name',
    ticketCreated: 'Ticket created successfully',
    fragmentClosedManually: 'Fragment closed manually',
    successToMarkConversationAsNotRead: 'You have marked the conversation as unread.',
    fragmentClosedManuallySubtitle:
      'The fragment was closed manually, you can reopen the conversation by sending a first contact template.',
    fragmentFailed: 'Fragment failed',
    fragmentFailedSubtitle:
      'The fragment failed, the conversation could not be initiated with the provided contact. Try editing the phone number (this will create another conversation) or select another template. Make sure the contact has an active WhatsApp and that the number is correct.',
    fragmetNew: 'New fragment',
    fragmetNewSubtitle: 'Conversation created successfully, you can send a first contact template.',
    goToMessage: 'Go to message',
    goToConversation: 'Go to conversation',
    fragmetExpired: 'Fragment expired',
    fragmetExpiredSubtitle:
      "YIt has been 24 hours since this contact's last message. The fragment was closed automatically, you can reopen the conversation by sending a first contact template.",
    resumeConversation: 'To continue the conversation send a template.',
    fragmentPending: 'Fragment pending',
    fragmentPendingSubtitle:
      'Template send, waiting response of client. You will be able to close the fragment after 24 hours after sending the template.',
    successToCreateContact: 'Contact created successfully',
    messagesContacts: 'The users you see in this list are the company users from',
    inviteNewUsers: 'If you wanna invite new ones or tweak some stuff, just give it a click ',
    successToMoveCoversationOfTeam: 'Conversation moved successfully',
    successAddedToEmbedding: 'Added to knowledge base successfully',
    successToUpdateStatus: 'Status updated successfully',
    summarizeAnswer: 'Summarize message',
    automaticResponse: 'Automatic response',
    currentTeamNotHaveAChannelAvaliable: 'Your current team does not have an available channel',
    currentTeamHaveAvaliableChannel: 'Your current team has available channels',
    waitMovingConversation: 'Wait, moving conversation...',
    reviewIfYuoHaveTemplatesIntTheLanguageOfTheConversation:
      'Review if you have templates in the language of the conversation',
    messageInfoAssignee:
      'The bot is the default responder for conversations. Once you assign an agent, including yourself, the conversation cannot be reassigned to the bot.',
    theBotIsAssignedDefault: 'The bot is assigned by default to conversations.',
    teamsAvaliables: 'Teams available',
    toCreateConversationSelectChannel: 'To create a conversation, select a channel',
    restrictedAttachment:
      "Please note that if you select a template with attachments for sending via Instagram or Facebook, only image files will be transmitted due to Meta's restrictions on media types. Any non-image attachments included in the template will not be sent on these channels.",
    webCheckIn:
      'To complete the web check in you must enter with your last name and Reservation number in the following link:',
    pressHereToCloseRightBar: 'Press here to close the right bar',
    upsUpdate: 'Oops! Looks like you need an update',
    freeLimiRreached: 'Free limit reached',
    upsUpdateDescription:
      'Hello! To access integrations with Instagram, Facebook, WhatsApp and activate our advanced AI Bot, you need to upgrade your plan.'
  },
  min: 'min',
  minors: 'Minors',
  moreInfo: 'More info',
  selectTeamToMoveConversation: 'Select team to move conversation',
  motor: 'Booking Engine',
  name: 'Name',
  nameOfGroup: 'Name of group',
  new: 'New',
  newConversation: 'New conversation',
  newconversation: 'New conversation',
  newGroup: 'New group',
  newgroup: 'New group',
  newChat: 'New chat',
  newVersionAvaliable: 'A new version of the app is available, please reload to update',
  next: 'Next',
  nights: 'Nights',
  reponseGenerateOfIA: 'Response generated by IA',
  no: 'No',
  noAssignee: 'No assignee',
  noInternet: 'No internet connection',
  noMessages: 'No messages',
  noResults: 'No results',
  noConversations: 'No conversations',
  noContent: 'No content',
  noTemplatesInThisLanguage: 'No templates in this language',
  noTemplatesAvailable: 'No templates available',
  noPhones: 'This contact does not have phones, edit the contact to add one',
  noEmails: 'This contact does not have emails, edit the contact to add one',
  noEmail: 'No email',
  noInfo: 'No information',
  NotificationOfAllMessages: 'Notifications of all messages',
  notificationsNotAllowedByBrowser: 'Notifications are not allowed by the browser',
  Messages: 'Messages',
  moveTo: 'Move to',
  markNotRead: 'Mark as not read',
  noMinors: 'No minors',
  noTags: 'No tags',
  notifications: 'Notifications',
  notification: {
    channels: 'Channel notifications',
    sound: 'Sound notifications',
    conversation: 'Conversation notifications',
    assigned: 'Assigned notifications',
    assignedToMe: 'Conversations assigned to me',
    unassigned: 'Conversations unassigned',
    assignedTooltip: 'Enable or disable assigned notifications',
    browser: 'Browser notifications',
    disbleNotifiacions: 'Disable notifications',
    enableNotifiacions: 'Enable notifications',
    disabledNotificationsConversation: 'Notifications for this conversation are disabled',
    muteConversation: 'Mute conversation',
    unmuteConversation: 'Unmute conversation',
    soundActivedToAllMessages: 'Sound activated for all messages',
    soundDisabledToAllMessages: 'Sound disabled for all messages',
    soundTooltip: 'Enable or disable sound notifications',
    channelsTooltip: 'Enable or disable channel notifications',
    browserTooltip: 'Enable or disable browser notifications',
    browserSubtitle: 'Enable or disable browser notifications'
  },
  open: 'Open',
  objectsRelations: 'Object Associations',
  objectAssociations: 'Object Associations',
  pagos: 'Payments',
  permissionCamera:
    'Sorry, we need camera and gallery permissions for this functionality to work properly.',
  person: 'Person',
  pending: 'Pending',
  pickDateFromCalendar: 'Pick date from calendar',
  phone: 'Phone',
  plantillas: 'Templates',
  pleaseWait: 'Please wait... ',
  pointofSale: 'Point of sale',
  published: 'Published',
  recordAudio: 'Record audio',
  resolved: 'Resolved',
  retry: 'Retry',
  retryLogin:
    'An error has occurred while logging in, please try again shortly, and if the error persists, contact Pxsol Support at: WHATSAPP_DE_SOPORTE. We regret the inconvenience caused. Error code: MENSAJE_DE_ERROR_ORIGINAL',
  errorCode: 'ORIGINAL_ERROR_MESSAGE',
  responseAutomatic: 'Response automatic',
  rooms: 'Rooms',
  responseGenerated: 'Response generated',
  save: 'Save',
  search: 'Search',
  searchInContacts: 'Search in contacts',
  searchContact: 'Search contact',
  searchBooking: 'Search booking',
  selectConversation: 'Select a conversation',
  selectDate: 'Select date',
  selectSingle: 'Select single date',
  selectMultiple: 'Select multiple dates',
  selectRange: 'Select range date',
  selectDateCheckInAndCheckOut: 'Select date check-in and check-out',
  sendMessageTo: 'Send message to',
  sendEmailTo: 'Send email to',
  skip: 'Skip',
  stopRecordAudio: 'Stop recording audio',
  success: 'Success',
  status: 'Status',
  successBooking: 'Booking created successfully',
  statusMessages: {
    info: 'Info',
    success: 'Success',
    warning: 'Warning',
    error: 'Error',
    open: 'Open',
    closed: 'Closed',
    expired: 'Expired',
    bug: 'Bug',
    added: 'Added',
    changed: 'Changed',
    removed: 'Removed',
    deprecated: 'Deprecated',
    fixed: 'Fixed',
    security: 'Security',
    pending: 'Pending',
    delivered: 'Delivered',
    read: 'Read',
    send: 'Send',
    new: 'New',
    failed: 'Failed',
    rejected: 'Rejected'
  },
  soundNotifications: 'Sound notifications',
  soundOfAllMessages: 'Sound of all messages',
  tags: 'Tags',
  task: 'Task',
  time: 'Time',
  team: 'Team',
  textToRewrite: 'Text to rewrite',
  textRewrited: 'Text rewrited',
  tickets: 'Tickets',
  ticket: 'Ticket',
  typeInDate: 'Type in a date',
  typeOfTicket: 'Type of ticket',
  trigger: 'Trigger',
  tooltip: {
    infoOfContact: 'Information of the contact to be associated with the ticket',
    infoOfcompany: 'Information of the company to be associated with the ticket'
  },
  question: 'Question',
  placeholders: {
    textAreaRewrite: 'E.g.: translate to English, draft as an email',
    textAreaCompose: 'E.g.: Respond to the user kindly that we do not have availability'
  },
  problem: 'Problem',
  tradename: 'Tradename',
  updating: 'Updating messages...',
  userData:
    'Sign in in the pop-up window, if you have already done so, wait a moment, if you have closed the window or have problems, press the Sign In button again and try again',
  view: 'View',
  viewBooking: 'View booking',
  viewInfo: 'View info',
  welcome: 'Welcome',
  whatsapp: 'Whatsapp',
  instagram: 'Instagram',
  facebook: 'Facebook',
  writeMessage: 'Type / to search',
  yes: 'Yes',
  youAreSure: 'Are you sure?',
  youAreSureAssosiateThisBooking: 'Are you sure you want to associate this booking?',
  youAreSureAssiciateThisContact: 'Are you sure you want to associate this contact?',
  labelAssociateBooking: 'The reservation that you are about to associate is the following:',
  labelAssociateContact: 'The contact that you are about to associate is the following:',
  labelCreateConver: 'You are about to start a conversation with the following contact:',
  goNextIntroSlider: 'Next',
  goPrevIntroSlider: 'Back',
  skipIntroSlider: 'Skip',
  doneIntroSlider: 'Got it!',
  scheduleDemo: 'Schedule Free Demo',
  selectContactToInitInternalConversation: 'Select contact to init internal conversation',
  soon: 'Soon',
  selectChannelToInitConversaton: 'Select channel to init conversation',
  selectChannelToConnect: 'Select channel to connect',
  selectTheTypeOfTicket: 'Select the type of ticket',
  unactivated: 'Unactivated',
  viewGuides: 'View Guides',
  writeAnInstruction: 'Write an instruction',
  helpGuidesLink: 'Guides',
  isConnectedwhatsappNumber: 'Your WhatsApp number is connected',
  isConnectedfacebookNumber: 'Your Facebook page is connected',
  isConnectedinstagramNumber: 'Your Instagram page is connected',
  isConnectwhatsappDialogTitle: 'Your WhatsApp number is connected',
  isConnectwhatsappDialogDescription:
    '!You are ready to start managing your WhatsApp messages from Pxsol Conversations!',
  connectwhatsappNumber: 'Connect your WhatsApp number',
  connectwhatsappNumberDialogTitle: 'Connect your WhatsApp number',
  connectwhatsappNumberDialogDescription:
    'Schedule a brief meeting with your executive so you can connect your number and start managing your WhatsApp from Pxsol Conversations',
  connectwhatsappDialogTitle: 'Connect your WhatsApp number',
  connectwhatsappDialogDescription:
    'Schedule a brief meeting with your executive so you can connect your number and start managing your WhatsApp from Pxsol Conversations',
  connectfacebookDialogTitle: 'Connect your Facebook page',
  connectfacebookDialogDescription:
    'Schedule a brief meeting with your executive so you can connect your Facebook page and start managing your Facebook messages from Pxsol Conversations',
  connectfacebookNumber: 'Connect your Facebook page',
  connectinstagramDialogTitle: 'Connect your Instagram page',
  connectinstagramDialogDescription:
    'Schedule a brief meeting with your executive so you can connect your Instagram page and start managing your Instagram messages from Pxsol Conversations',
  connectinstagramNumber: 'Connect your Instagram page',
  connectwidgetDialogTitle: 'Connect your Widget',
  connectwidgetDialogDescription:
    'Schedule a brief meeting with your executive so you can connect your Widget and start managing your messages from Pxsol Conversations',
  isConnectinstagramDialogDescription: 'Nice! Your Instagram page is connected',
  isConnectinstagramDialogTitle: 'Your Instagram page is connected',
  isConnectfacebookDialogDescription: 'Nice! Your Facebook page is connected',
  isConnectfacebookDialogTitle: 'Your Facebook page is connected',
  isConnectwidgetDialogDescription: 'Nice! Your Widget is connected',
  isConnectwidgetDialogTitle: 'Your Widget is connected',
  warning: 'Warning',
  waiting: 'Waiting',
  daily_limit_reached: 'AI request limit for today has been reached, please try again later'
};

export default resource;
