import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Platform } from 'react-native';
import i18n from '@/providers/Localization';
import { Routes } from './routes';
import HeaderInfo from '@/components/header/HeaderInfo';
import { RootState, useAppSelector } from '@/redux/store';

import colors from '../../config/colors';
import WaitingComponent from '@/components/waiting';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { TABLET_SCREEN } from '@/utils/globales';
import AppStackMobile from '@/layout/mobile/AppStackMobile';

import RouteNavigations from './RouteNavigations';
import PrivacyAndTerms from '@/screens/PrivacyAndTerms';

const Stack = createStackNavigator();

function PrivateStack() {
  const teamId = useAppSelector((state: RootState) => state.user.team?.id);
  const themeMode = useAppSelector((state: RootState) => state.settings.themeMode);

  const waitingForResponse = useAppSelector(
    (state: RootState) => state.conversations.waitingForResponse
  );
  const [isPc] = useMediaQuery([
    {
      minWidth: TABLET_SCREEN
    }
  ]);

  return isPc && Platform.OS === 'web' ? (
    <Stack.Navigator
      initialRouteName={Routes.Team}
      screenOptions={{
        headerShown: true,
        header: () => <HeaderInfo />,
        cardStyle: {
          backgroundColor: colors[themeMode]?.backgroundSecondary
        }
      }}
    >
      <Stack.Screen
        name={Routes.Team}
        initialParams={{ team: teamId, id: '' }}
        options={{
          title: i18n.t('team'),
          headerTitle: i18n.t('team'),
          cardStyle: {
            backgroundColor: colors[themeMode]?.backgroundSecondary
          }
        }}
        component={waitingForResponse ? WaitingComponent : RouteNavigations}
      />
      <Stack.Screen
        name={Routes.PrivacyAndTerms}
        component={PrivacyAndTerms}
        options={{
          headerShown: false
        }}
      />
    </Stack.Navigator>
  ) : (
    <AppStackMobile />
  );
}

export default React.memo(PrivateStack);
