import React, { useEffect, useMemo } from 'react';
import { DrawerContentComponentProps } from '@react-navigation/drawer';

import {
  Bells,
  CogsMultiple,
  Customization,
  DashboardCustomize,
  IconConnection,
  IconHelp
} from '@/assets/icons/Icons';

import DrawerSection from '@/atoms/DrawerSection';
import ItemDrawerSettings from '@/atoms/ItemDrawerSettings';
import ItemLogout from '@/atoms/ItemLogout';

import { RouteSettings, Routes } from '../../../routers/routes';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import HeaderSidebarFilters from '../sidebarFilters/HeaderSidebarFilters';
import { getWorkflows } from '@/redux/slices/automatization/requests';
import { setGettingWorkflows } from '@/redux/slices/automatization/index';
import { setWorkflows } from '@/redux/actionTypes/index';
import { ThemedScrollView, TVView } from '@/components/themed';

/**
 *
 * @param props DrawerContentComponentProps
 * @returns {React.ReactElement}
 */

function ContentSiderbarLeftSettings(props: DrawerContentComponentProps): React.ReactElement {
  const dispatch = useAppDispatch();
  const { state } = useMemo(() => props, [props]);

  const activeRoute = useMemo(() => state?.routeNames[state?.index], [state]);
  const teamId = useAppSelector((state: RootState) => state.user?.team?.id);
  const isPxsol = useAppSelector((state: RootState) => state.user?.user?.isPxsol);
  const idStringWorkflow = useAppSelector(
    (state: RootState) => state.automatization.idStringWorkflow
  );

  const handlePessAutimatization = () => {
    if (!teamId) return;
    dispatch(setGettingWorkflows(true));
    dispatch(getWorkflows({ teamId }))
      .then((res) => {
        console.log('Workflows loaded');
        dispatch(
          setWorkflows({
            idString: idStringWorkflow,
            workflows: res?.data
          })
        );
      })
      .catch((err) => {
        console.log('Error getting workflows', err);
      })
      .finally(() => {
        dispatch(setGettingWorkflows(false));
      });
    props.navigation.navigate(RouteSettings.settingsChat);
  };

  const drawerSections = {
    ajustsOfHotel: [
      {
        id: '2',
        icon: CogsMultiple,
        routeName: RouteSettings.settingsChat,
        onPress: () => handlePessAutimatization(),
        idString: 'navigation.automatizations',
        avaliable: true,
        visible: true
      },
      {
        id: '1',
        icon: DashboardCustomize,
        routeName: RouteSettings.settingsChannels,
        onPress: () => props.navigation.navigate(RouteSettings.settingsChannels),
        idString: 'navigation.channels',
        avaliable: true,
        visible: true
      }
    ],
    ajustOfApp: [
      {
        id: '3',
        icon: Bells,
        routeName: RouteSettings.settingsNotifications,
        onPress: () => props.navigation.navigate(RouteSettings.settingsNotifications),
        idString: 'navigation.notifications',
        avaliable: true,
        visible: true
      },
      {
        id: '4',
        icon: Customization,
        routeName: RouteSettings.settingsCustomizations,
        onPress: () => props.navigation.navigate(RouteSettings.settingsCustomizations),
        idString: 'navigation.customization',
        avaliable: true,
        visible: true
      },
      {
        id: '6',
        icon: IconConnection,
        routeName: RouteSettings.connections,
        onPress: () => props.navigation.navigate(RouteSettings.connections),
        idString: 'navigation.connections',
        avaliable: true,
        visible: isPxsol
      }
    ],
    centerOfHelp: [
      {
        id: '5',
        icon: IconHelp,
        routeName: RouteSettings.help,
        onPress: () => props.navigation.navigate(RouteSettings.help),
        idString: 'navigation.help',
        avaliable: true,
        visible: true
      }
    ]
  };

  return (
    <ThemedScrollView
      bgColor="backgroundSecondary"
      style={{
        height: '100%',
        width: '100%',
        padding: 0,
        margin: 0
      }}
      {...props}
    >
      <HeaderSidebarFilters
        name={activeRoute}
        closeDrawer={() => props.navigation.closeDrawer()}
        navigateTo={{
          name: Routes.Settings,
          params: { id: '' },
          state: {
            index: 0,
            routes: [
              {
                name: RouteSettings.settingsChat,
                params: { id: '' }
              }
            ]
          }
        }}
      />
      <TVView
        style={{
          padding: 10
        }}
      >
        {Object.entries(drawerSections).map(([sectionId, items]) => (
          <DrawerSection key={sectionId} idString={sectionId}>
            {items.map((item, index) => {
              if (item.visible) {
                return (
                  <ItemDrawerSettings
                    key={index}
                    icon={item.icon}
                    style={{ routeName: item.routeName, activeRoute: activeRoute }}
                    onPress={item?.onPress}
                    noAvaliable={!item.avaliable}
                    idString={item.idString}
                    absolute={false}
                    color={item.avaliable ? 'text' : 'secondary'}
                  />
                );
              }
            })}
          </DrawerSection>
        ))}

        <ItemLogout />
      </TVView>
    </ThemedScrollView>
  );
}

/**
 * Exportamos el componente
 * @exports ContentSiderbarLeftSettings
 * @returns {React.ReactElement}
 * @description Exportamos el componente para poder usarlo en otros ficheros, retorna el contenido del sidebar izquierdo
 */
export default React.memo(ContentSiderbarLeftSettings);
