import React, { memo, useEffect, useMemo, useState } from 'react';
import { ThemedScrollView, THView } from '../themed';
import SectionFrom from '../partials/automatizations/SectionFrom';
import InputTextForm from '../form/InputTextForm';
import { ConnectionData } from '@/redux/slices/automatization/interface';
import translate from '@/helpers/traslations';
import { NativeSyntheticEvent, TextInputChangeEventData } from 'react-native';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import MainCta from '@/atoms/MainCta';
import { connectChannelWpp } from '@/redux/slices/automatization/requests';
import SelectForm from '../form/SelectForm';
import ObjectView from '@/atoms/ObjectView';

const FormConnections = () => {
  const dispatch = useAppDispatch();
  const teamId = useAppSelector((state: RootState) => state.user.team.id);
  const companyId = useAppSelector((state: RootState) => state.user?.company?.IDEmpresas);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<object>(undefined);
  const [success, setSuccess] = useState<object>(undefined);
  const [connectionData, setConnectionData] = useState<ConnectionData>({
    idChannelDefinition: null,
    companyId,
    teamId,
    channelLabel: ''
  } as ConnectionData);

  useEffect(() => {
    if (teamId) {
      setTeamId(teamId);
    }
    if (companyId) {
      setCompanyId(companyId);
    }
    return () => {
      setError(undefined);
      setLoading(false);
    };
  }, [companyId, teamId]);

  const onChangeChannelData = (value: NativeSyntheticEvent<TextInputChangeEventData>) => {
    error && setError(undefined);
    setConnectionData((prev) => ({ ...prev, channelData: value.nativeEvent.text }));
  };

  const onChangeChannelId = (value: NativeSyntheticEvent<TextInputChangeEventData>) => {
    error && setError(undefined);
    setConnectionData((prev) => ({ ...prev, channelId: value.nativeEvent.text }));
  };

  const onChangeBussines_id = (value: NativeSyntheticEvent<TextInputChangeEventData>) => {
    error && setError(undefined);
    setConnectionData((prev) => ({ ...prev, bussines_id: value.nativeEvent.text }));
  };

  const onChangeChannelHotelName = (value: NativeSyntheticEvent<TextInputChangeEventData>) => {
    error && setError(undefined);
    setConnectionData((prev) => ({ ...prev, channelHotelName: value.nativeEvent.text }));
  };

  const setTeamId = (teamId: number) => {
    error && setError(undefined);
    setConnectionData((prev) => ({ ...prev, teamId }));
  };

  const setCompanyId = (companyId: number) => {
    error && setError(undefined);
    setConnectionData((prev) => ({ ...prev, companyId }));
  };

  const setSelectedChannel = (channel: string) => {
    error && setError(undefined);
    setConnectionData((prev) => ({ ...prev, idChannelDefinition: Number(channel) }));
  };

  const onChangeNamespace_id = (value: NativeSyntheticEvent<TextInputChangeEventData>) => {
    error && setError(undefined);
    setConnectionData((prev) => ({ ...prev, namespace_id: value.nativeEvent.text }));
  };

  const isButtonDisabled = useMemo(() => {
    return (
      !connectionData?.channelData ||
      !connectionData?.channelId ||
      !connectionData?.bussines_id ||
      !connectionData?.channelHotelName
    );
  }, [connectionData]);

  const hanldeConnectChannel = () => {
    setError(undefined);
    setLoading(true);
    dispatch(connectChannelWpp(connectionData))
      .then((res) => {
        console.log(res);
        setSuccess(res?.data);
        setLoading(false);
        setConnectionData({
          idChannelDefinition: 1,
          companyId,
          teamId,
          channelLabel: ''
        } as ConnectionData);
      })
      .catch((err) => {
        console.log(err);
        setError(err?.data);
        setLoading(false);
      });
  };
  console.log('success', success);

  const channels: { label: string; id?: string; value: string; available: boolean }[] = useMemo(
    () => [
      {
        label: `whatsapp`,
        id: '1',
        value: '1',
        available: true
      },
      {
        label: `instagram`,
        id: '2',
        value: '2',
        available: true
      },
      {
        label: `facebook`,
        id: '3',
        value: '3',
        available: true
      }
    ],

    []
  );

  return (
    <ThemedScrollView
      showsVerticalScrollIndicator={false}
      bgColor="background"
      style={{
        flex: 1,
        alignItems: 'center',
        maxWidth: 800,
        width: '100%'
      }}
      contentContainerStyle={{
        padding: 16
      }}
    >
      <SectionFrom
        title="Conexiones de Canales"
        idStringSubtitle="Sólo PXSOL - Vas a poder conectar canales (instagram, facebook, whastapp), se toma el equipo y el C de donde estás logueado."
      >
        <InputTextForm
          value={connectionData?.channelData ?? ''}
          onChange={onChangeChannelData}
          isRequired
          label={translate('channelData')}
          placeholder={translate('actions.typeAChannelData')}
          errorText="Error"
          bgColor={connectionData?.channelData ? 'backgroundActive' : 'background'}
        />
        <InputTextForm
          value={connectionData?.channelId ?? ''}
          onChange={onChangeChannelId}
          isRequired
          label={translate('channelId')}
          placeholder={translate('actions.typeAChannelId')}
          errorText="Error"
          bgColor={connectionData?.channelId ? 'backgroundActive' : 'background'}
        />
        <InputTextForm
          value={connectionData?.bussines_id ?? ''}
          onChange={onChangeBussines_id}
          isRequired
          label={translate('bussines_id')}
          placeholder={translate('actions.typeABussines_id')}
          errorText="Error"
          bgColor={connectionData?.bussines_id ? 'backgroundActive' : 'background'}
        />
        <InputTextForm
          value={connectionData?.channelHotelName ?? ''}
          onChange={onChangeChannelHotelName}
          isRequired
          label={translate('channelHotelName')}
          placeholder={translate('actions.typeAChannelHotelName')}
          errorText="Error"
          bgColor={connectionData?.channelHotelName ? 'backgroundActive' : 'background'}
        />
        <InputTextForm
          value={connectionData?.namespace_id ?? ''}
          onChange={onChangeNamespace_id}
          isRequired
          label={translate('channelNamespace_id')}
          placeholder={translate('actions.typeANamespace_id')}
          errorText="Error"
          bgColor={connectionData?.namespace_id ? 'backgroundActive' : 'background'}
        />
        <SelectForm
          options={channels}
          label={translate('selectChannelToConnect')}
          isRequired
          placeholder={translate('selectChannelToConnect')}
          errorText={translate('selectChannelToConnect')}
          onChangeSelect={setSelectedChannel}
          isInvalid={false}
          defaultValue={connectionData.idChannelDefinition?.toString() ?? ''}
          initialLabel={connectionData.idChannelDefinition?.toString() ?? ''}
        />
        <THView
          style={{
            justifyContent: 'flex-end'
          }}
        >
          <MainCta
            disabled={isButtonDisabled}
            labelId="actions.connect"
            onPress={hanldeConnectChannel}
            loading={loading}
          />
        </THView>
      </SectionFrom>
      <SectionFrom
        title={error ? 'Error' : success ? 'Conectado' : 'Previsualización'}
        idStringSubtitle="Información del la conexión actual"
        style={{ marginTop: 16, width: '100%' }}
      >
        {success ? (
          <ObjectView object={success} trl={false} success />
        ) : error ? (
          <ObjectView object={error} error trl={false} />
        ) : (
          <ObjectView object={connectionData} trl={false} />
        )}
      </SectionFrom>
    </ThemedScrollView>
  );
};

export default memo(FormConnections);
