import { ThemedScrollView } from '@/components/themed';
import { RootState, useAppSelector } from '@/redux/store';
import React from 'react';
import { TextStyle } from 'react-native';
import Markdown from 'react-native-markdown-display';

const legalContent = `
# Política de Privacidad


## Introducción

En Pxsol, respetamos tu privacidad y nos comprometemos a proteger cualquier información personal que nos proporciones al utilizar nuestra aplicación de mensajería "Conversaciones Pxsol".

## Información que recolectamos

### Datos personales

Recopilamos información como tu nombre, correo electrónico, número de teléfono, y datos de tus perfiles en redes sociales (si otorgas permiso).

### Datos de uso

Incluimos datos sobre tu interacción con la aplicación, mensajes enviados y recibidos, y las configuraciones de tus cuentas conectadas.

## Uso de la información

La información recopilada se utiliza para:

- Proporcionar servicios de mensajería a través de WhatsApp, Instagram, y Facebook.
- Mejorar la experiencia del usuario mediante análisis de datos no identificables.
- Enviar notificaciones relevantes sobre el servicio.

## Compartición de información

Compartimos datos con terceros sólo cuando es necesario para:

- Operar servicios de mensajería mediante la API de Meta.
- Cumplir con regulaciones legales o solicitudes judiciales.

> No vendemos ni cedemos tus datos a terceros con fines comerciales.

## Seguridad de la información

Implementamos medidas de seguridad técnicas y organizativas para proteger tus datos contra accesos no autorizados, pérdida o alteración.

## Derechos del usuario

Puedes solicitar acceso, rectificación o eliminación de tus datos en cualquier momento enviando un correo a: [soporte@pxsol.com].

## Cookies y tecnologías similares

Utilizamos cookies para mejorar la funcionalidad de nuestra aplicación y analizar el comportamiento de los usuarios.

## Actualizaciones

Nos reservamos el derecho de actualizar esta Política de Privacidad en cualquier momento. Te notificaremos los cambios mediante nuestra aplicación.

## Contacto

Si tienes preguntas o inquietudes sobre nuestra política de privacidad, escríbenos a: [soporte@pxsol.com].

---

# Términos y Condiciones


## Introducción

Los presentes términos regulan el uso de la aplicación "Conversaciones Pxsol". Al utilizar, aceptas las condiciones aquí descritas.

## Uso del servicio

"Conversaciones Pxsol" permite la integración con las APIs de WhatsApp, Instagram y Facebook para el envío y recepción de mensajes.

El usuario es responsable de cumplir con las políticas de las plataformas (Meta) y evitar usos indebidos.

## Restricciones

No se permite el uso del servicio para actividades ilegales, spam, o contenido prohibido por las políticas de Meta.

## Integración con APIs

La aplicación requiere permisos específicos de las plataformas conectadas (WhatsApp, Instagram, Facebook). El usuario deberá otorgar estos permisos para el correcto funcionamiento.

## Costos

El servicio incluye un límite mensual de mensajes según el plan contratado. Los mensajes adicionales tendrán costos específicos, como se detalla en el contrato con Pxsol.

## Suspensión o terminación

Pxsol puede suspender el servicio si detecta incumplimientos a estos términos o a las políticas de Meta.

## Actualización de precios

Los precios pueden ajustarse mensualmente según la inflación u otros factores, como se detalla en el contrato.

## Exoneración de responsabilidades

Pxsol no se hace responsable por fallos derivados de problemas en las plataformas de Meta (WhatsApp, Instagram o Facebook) o por la revocación de permisos por parte de estas.

## Resolución de disputas

Cualquier disputa se resolverá de acuerdo con las leyes de [país de operación de Pxsol].

## Contacto

Para consultas o soporte técnico, comunícate a: [soporte@pxsol.com].
`;

const PrivacyAndTerms = () => {
  const themeMode = useAppSelector((state: RootState) => state.settings.themeMode);
  const markdownStyles = {
    body: {
      padding: 16,
      fontSize: 16,
      lineHeight: 24,
      color: themeMode === 'dark' ? '#FFFFFF' : '#333333'
    } as TextStyle,
    heading1: {
      fontSize: 28,
      fontWeight: 'bold',
      marginVertical: 16,
      color: themeMode === 'dark' ? '#FFFFFF' : '#000000'
    } as TextStyle,
    heading2: {
      fontSize: 24,
      fontWeight: 'bold',
      marginVertical: 12,
      color: themeMode === 'dark' ? '#FFFFFF' : '#222222'
    } as TextStyle,
    heading3: {
      fontSize: 20,
      fontWeight: 'bold',
      marginVertical: 8,
      color: themeMode === 'dark' ? '#FFFFFF' : '#444444'
    } as TextStyle,
    paragraph: {
      marginVertical: 8
    } as TextStyle,
    list_item: {
      marginVertical: 4
    } as TextStyle,
    blockquote: {
      borderLeftWidth: 4,
      borderLeftColor: themeMode === 'dark' ? '#FFFFFF' : '#666666',
      backgroundColor: themeMode === 'dark' ? '#121212' : '#F0F0F0',
      paddingLeft: 16,
      marginVertical: 8,
      fontStyle: 'italic'
    } as TextStyle,
    hr: {
      marginVertical: 24,
      height: 1,
      backgroundColor: themeMode === 'dark' ? '#FFFFFF' : '#CCCCCC'
    } as TextStyle,
    link: {
      color: themeMode === 'dark' ? '#FFFFFF' : '#0077CC',
      textDecorationLine: 'underline' as const
    } as TextStyle,
    strong: {
      fontWeight: 'bold'
    } as TextStyle
  };

  return (
    <ThemedScrollView
      contentContainerStyle={{
        padding: 16
      }}
      bgColor="background"
      style={{ flex: 1 }}
      showsVerticalScrollIndicator={false}
    >
      <Markdown style={markdownStyles}>{legalContent}</Markdown>
    </ThemedScrollView>
  );
};

export default PrivacyAndTerms;
