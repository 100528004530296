import React, { memo } from 'react';
import { THView, TText, TVView } from '@/components/themed';
import translate from '@/helpers/traslations';
import TextFormatter from './TextFormatter';

const ObjectView = ({
  object,
  trl = true,
  error,
  success
}: {
  object: object;
  trl?: boolean;
  error?: boolean;
  success?: boolean;
}) => {
  const renderObject = (obj: object) => {
    return Object.keys(obj).map((key) => {
      const value = obj[key];
      return (
        <THView
          key={key}
          style={{ alignItems: 'flex-start', justifyContent: 'flex-start', gap: 8 }}
        >
          {typeof value === 'object' && value !== null ? (
            <TVView>
              <TText
                style={{
                  fontWeight: 'bold',
                  color: error ? '#ff6b6b' : success ? '#51cf66' : '#90cae8'
                }}
              >
                {trl ? translate(key) : key}:
              </TText>
              <TVView style={{ justifyContent: 'flex-start', marginLeft: 20 }}>
                {renderObject(value)}
              </TVView>
            </TVView>
          ) : (
            <THView style={{ flexDirection: 'row', alignItems: 'center', gap: 8 }}>
              <TText
                style={{
                  fontWeight: 'bold',
                  color: error ? '#ff6b6b' : success ? '#51cf66' : '#90cae8'
                }}
              >
                {trl ? translate(key) : key}:
              </TText>
              <TextFormatter
                children={value}
                color={error ? 'pxsol' : success ? 'success' : 'objectValue'}
              />
            </THView>
          )}
        </THView>
      );
    });
  };

  return <TVView style={{ width: '100%' }}>{renderObject(object)}</TVView>;
};

export default memo(ObjectView);
