import React, { memo } from 'react';
import { TVView } from '@/components/themed';
import FormConnections from '@/components/connections/FormConnections';

const Connections = () => {
  return (
    <TVView
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <FormConnections />
    </TVView>
  );
};

export default memo(Connections);
